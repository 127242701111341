 <template>
  <v-tooltip
    bottom
    open-delay="200"
    :disabled="isMenuOpen"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <user-ref
          :open-on-hover="false"
          :id="$store.getters.currentUser.id"
          :attach="false"
          :avatar-props="{normal: true}"
          hide-fullname
          hide-email
          hide-phone
          hide-group
          left
          offset-y
          z-index=200
          ref="user-ref"
        >
          <template v-slot:popup>
            <v-card class="ma-0">
              <v-list>
                <v-list-item @click="openMyProfile">
                  <v-list-item-content>
                    <user-ref
                      :avatarProps="{visible: false}"
                      :id="$store.getters.currentUser.id"
                      :avatar-props="{large: true}"
                      hide-email
                      hide-phone
                      block-popup
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item>
                  <v-list-item-content>
                    <v-select
                      class="mt-6"
                      :items="availableCultures"
                      item-text="name"
                      item-value="code"
                      :label="$t('t.ApplicationLanguage')"
                      v-model="culture"
                    >
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-select
                      :items="availableCurrencies"
                      item-text="code"
                      item-value="code"
                      :label="$t('t.UserCurrency')"
                      v-model="currency"
                    >
                      <template v-slot:item="{item}">
                        {{item.code}} {{item.isMain ? `(${$t('t.GlobalCurrency')})`:''}}
                      </template>
                      <template v-slot:selection="{item}">
                        {{item.code}} {{item.isMain ? `(${$t('t.GlobalCurrency')})`:''}}
                      </template>
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item @click="showQRCode = true">
                  <v-list-item-content>
                    {{$t('t.MobileApp')}}
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-list-item>
                  <v-btn
                    class="mt-2"
                    rounded
                    block
                    v-ripple
                    color="primary"
                    @click.stop="logout()"
                  >
                    <v-icon class="mr-2">{{$icon('i.Logout')}}</v-icon>
                    <span>{{$t('t.Disconnect')}}</span>

                  </v-btn>
                </v-list-item>
              </v-list>
            </v-card>
            <v-dialog
              max-width="75vw"
              width="fit-content"
              v-model="showQRCode"
            >
              <div class="d-flex flex-column align-center pa-4">
                <div class="text-h2 text-center">{{$t('t.AccessPWAViaQrCode')}}</div>
                <qr-code
                  class="ma-16"
                  :text="qrCodeUrl"
                  :width=500
                />
              </div>
            </v-dialog>
          </template>
        </user-ref>
      </div>
    </template>
    <span>{{$t('t.ManageAccount')}}</span>
  </v-tooltip>
</template>

<script>

import authenticationController from '@/authenticationController'

export default {
  components: {
    QrCode: () => import('@/components/qr-code'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  data () {
    return {
      availableCurrencies: [],
      availableCultures: [],
      isMenuOpen: false,
      showQRCode: false
    }
  },
  async created () {
    const applicationSettingsRequest = await this.$http().get('/core/v6/settings/currencies')
    this.availableCurrencies = applicationSettingsRequest.data.currencies

    const e = await this.$http().get('/core/v6/settings/cultures/ui')
    let uiCultures = e.data.cultures
    uiCultures = uiCultures.map(_ => {
      return {
        name: this.$t(`t.Culture${_.slice(0, -3).toUpperCase()}`),
        code: _
      }
    })
    this.availableCultures = uiCultures
  },
  computed: {
    currency: {
      get () {
        return this.$store.getters.currentUser.currency
      },
      set (value) {
        this.$store.dispatch('setCurrentUser', { currency: value })
      }
    },
    culture: {
      get () {
        return this.$store.getters.currentUser.culture
      },
      set (value) {
        this.$store.dispatch('setCurrentUser', { culture: value })
      }
    },
    qrCodeUrl () {
      const url = new URL(document.location)
      return `${url.origin}${url.pathname}#${this.$router.getRoutes().find(r => r.name === 'mobility-login').path}?session=${encodeURIComponent(sessionStorage.getItem('access_token'))}`
    }
  },
  methods: {
    logout () {
      this.$http().logout()
      authenticationController.sendToLogin(this.$router)
    },
    openMyProfile () {
      this.$refs?.['user-ref'].closePopUp()
      if (this.$route.path !== `/users/${this.$store.getters.currentUser.id}`) {
        this.$router.push(`/users/${this.$store.getters.currentUser.id}`)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.division-list
  min-height unset

.divisions
  max-height 200px
  overflow-x hidden
  overflow-y auto
  width 100%
</style>
